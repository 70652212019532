.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nums {
    display: flex;
    justify-content: center;
    align-items: center;

    .num {
      width: 32px;
      height: 32px;
      border-radius: 20px;
      border: 1px;
      background: #FFFFFF;
      border: 1px solid #DAE0E6;

      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.1px;
      text-align: center;
      color: #5F6D7E;

      &__active {
        color: #51AF3D;
        border-color: #51AF3D;
      }

      &__check {
        margin-top: -1px;
      }
    }
  }
  .buttons {
    display: flex;
    gap: 30px;

    .button_back {
      background: var(--white);
      width: 56px;
      height: 60px;
      padding: 12px 18px 12px 18px;
      border-radius: 6px;
      border: 1px;
      gap: 6px;
      border: 1px solid #5F6D7E;
      box-shadow: 0px 1px 2px 0px #1018280A;
    }
    .button_next {
      width: 106px;
      height: 60px;
      padding: 12px 18px 12px 18px;
      border-radius: 6px;
      gap: 6px;

      &__disabled {
        background: #5F6D7E;
        border: 1px solid #5F6D7E;
        box-shadow: 0px 1px 2px 0px #1018280A;
      }
    }
  }
}

@media (max-width: 800px) {
  .footer {
    .buttons {
      gap: 15px;
    }
  }
}

@media (max-width: 767.9px) {

}
@media (max-width: 560px) {
  .footer {
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 30px;
    background: transparent;

    .nums {
      order: 1;
      margin: 0 auto;
    }
    .buttons {
      order: 0;
      padding-left: 30px;
    }
  }
}