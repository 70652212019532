.inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 60px;

  .input_container {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    max-width: 343px;
    height: 100%;
    max-height: 56px;
    border-radius: 5px;
    text-align: left;
    border: 1px solid #EAEBF0;
    box-shadow: 0px 1px 2px 0px #1018280A;
    cursor: pointer;

    label {
      padding: 16px;
      margin-left: 10px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 15px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #51AF3D;
  position: absolute;
  left: 4px;
  top: 19px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (max-width: 1080px) {
  .inputs {
    gap: 30px;
    padding-right: 15px;

    .input_container {
      min-width: 270px;
      max-width: 343px;
      max-height: 56px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
}