.slider {
  width: 100%;
  max-width: 465px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &__value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 310px;
    height: 48px;
    padding: 12px 16px;
    border-radius: 6px;
    gap: 10px;
    border: 2px solid #51AF3D;

    p {
      color: #272D37;

      font-family: Roboto;
      font-size: 32px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: center;
    }
  }

  &__input {
    margin-top: 50px;
  }
}

.slider__input {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  background: #51AF3D;
  outline: none;
  border-radius: 10px;
}
.slider__input::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #E3FFDE;
  border-radius: 10px;
}
.slider__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background: #51AF3D;
  border: 2px solid var(--white);
  cursor: pointer;
  border-radius: 50%;
}
.slider__input::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #51AF3D;
  border: 2px solid var(--white);
  cursor: pointer;
  border-radius: 50%;
}

@media (max-width: 992px) {
  .slider {
    width: 100%;
    max-width: 400px;

    &__value {
      width: 100%;
      max-width: 240px;
    }

    &__input {
      margin-top: 50px;
      margin-right: 30px;
    }
  }
}

@media (max-width: 560px) {
  .slider {
    max-width: 100%;

    &__value {
      width: 100%;
      max-width: 100%;
      max-height: 48px;
      box-sizing: border-box;

      p {
        width: 100%;
        color: #272D37;

        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }

    &__input {
      margin: 50px 0 0 0;
    }
  }
}