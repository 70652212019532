.layout {
  height: 100%;
  min-height: 46px;
  max-height: 66px;
  display: flex;
  justify-content: center;
  padding: 10px 30px;
  gap: 10px;
  background-color: var(--dark-grey);
  color: var(--white);

  .container {
    justify-content: space-between;
  }
}