.logo {
  display: flex;
  align-items: center;
  width: 142.77px;
  height: 34.02px;
  margin-top: 5px;

  img {
    width: 100%;
    height: auto;
  }
}