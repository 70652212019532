:root {
  /* General */
  --font-family: 'IBMPlexSans', sans-serif;
  --font-family-roboto: 'Roboto', sans-serif;

  /* Colors library */
  --white: #FFFFFF;
  --white1: #F1F1F1;
  --white2: #F8F8F8;
  --neutral: #EAEBF0;
  --light-blue: #5F6D7E;
  --light-blue1: #64748B;
  --light-blue2: #346fe3;
  --blue: #437EF7;
  --dark-blue: #272D37;
  --dark-blue1: #191D23;
  --dark-blue2: #1018280A;
  --dark-blue3: #171A20;
  --light-black: #323232;
  --black: #000000;
  --black1: #1E1E1E;
  --light-grey: #D1D2D4;
  --light-grey1: #E0E0E0;
  --light-grey2: #D9D9D9;
  --grey: #7D7D7D;
  --dark-grey: #393939;
  --light-green: #72BF44;
  --green: #2D7D2F;
  --gold-prim: #FCC13F;
  --dark-gold-prim: #E7A410;

  /* Animation */
  --transition: background-color 0.3s, color 0.3s, transform 0.3s;

  --box-shadow: 0 1px 2px 0 var(--dark-blue2);
  --bg-opacity1: rgba(0,0,0,.6);
  --bg-opacity2: rgba(0,0,0,.2);
}
