.footer {
  .layout {
    max-width: 100%;
    background: var(--white);
    border-top: 1px solid var(--neutral);
  }
}

@media (max-width: 767.9px) {
  .footer {
    .layout {
      border-top: none;
    }
  }
}
@media (max-width: 560px) {
  .footer {
    .layout {
      min-height: 100px;
      margin-bottom: 30px;
    }
  }
}