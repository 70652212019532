@font-face {
  font-family: 'IBMPlexSans';
  src: url('./IBMPlexSans/IBMPlexSans-Medium.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./Inter/Inter-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto/Roboto.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}