.content {
  display: flex;
  gap: 60px;

  .card {
    width: 215px;
    height: 325.44px;
    padding: 0px 0px 14.76px 0px;
    border-radius: 10px;
    border: 2px;
    cursor: pointer;
    transition: 0.3s;

    background: #FFFFFF;
    border: 2px solid #F3F3F3;

    &:hover,
    &__active {
      border-color: #51AF3D;
    }

    p {
      max-width: 160.87px;
      padding: 22px 0 0 15px;
      color: #5F6D7E;

      font-family: Roboto;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.1px;
      text-align: left;
    }
  }
}

@media (max-width: 992px) {
  .content {
    gap: 30px;
  }
}

@media (max-width: 830px) {
  .content {
    gap: 15px;
    padding-right: 15px;

    .card {
      width: 100%;
      max-width: 215px;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 560px) {
  .content {
    .card {
      width: 145px;
      height: 219px;

      p {
        max-width: 160.87px;
        padding: 10px;
        color: #5F6D7E;

        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: -0.01em;
        text-align: left;
      }
    }
  }
}