.menu {
  ul {
    display: flex;
    flex-direction: row;

    li {
      margin-right: 45px;
    }
  }

  a {
    color: #5F6D7E;

    font-family: IBM Plex Sans;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }
}

@media (max-width: 992px) {
  .menu {
    ul {
      min-width: 200px;
      li {
        margin-right: 15px;
      }
    }
  }
}