.promotion {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  &__container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: end;
    gap: 64px;
    padding: 30px 0;
  }

  .left_block {
    max-width: 720px;

    .title {
      margin-bottom: 24px;
      text-transform: uppercase;
      color: #323232;

      font-size: 36px;
      font-weight: 700;
      line-height: 54px;
      letter-spacing: 0em;
      text-align: left;


      span {
        color: #51AF3D;
      }

    }
    .desc {
      max-width: 400px;
      margin-bottom: 32px;
      color: #272D37;

      font-family: Roboto;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;

    }
    .btn {
      margin-bottom: 80px;
    }

    .partners {
      margin-top: 32px;

      &__cards {
        display: flex;
        justify-content: center;
        align-items: start;
        gap: 30px;

        .card {
          max-width: 230px;
          height: auto;
          box-sizing: border-box;

          h3 {
            margin-top: 15px;
            color: #1B192A;

            font-family: Inter;
            font-size: 36px;
            font-weight: 600;
            line-height: 46px;
            letter-spacing: 0px;
            text-align: left;
          }
          p {
            margin-top: 15px;
            color: #34314F;

            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
    }
  }

  .right_block {
    .hero_img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      max-width: 832.78px;
      height: auto;
      padding-top: 14px;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1332px) {
  .promotion {
    &__container {
      padding: 30px;
    }
  }
}
@media (max-width: 1280px) {
  .promotion {
    .left_block {
      max-width: 600px;

      .title {
        span {
          display: block;
          color: #51AF3D;
        }
      }
    }
  }
}
@media (max-width: 1080px) {
  .promotion {
    .left_block {
      .partners {
        &__cards {
          justify-content: flex-start;
        }
      }
    }

    .right_block {
      .hero_img {
        max-width: 450px;
      }
    }
  }
}
@media (max-width: 992px) {
  .promotion {
    .right_block {
      .hero_img {
        top: 30%;
        transform: translateY(-30%);
      }
    }
  }
}
@media (max-width: 920px) {
  .promotion {
    .right_block {
      .hero_img {
        max-width: 350px;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .promotion {
    position: initial;
    height: calc(100vh - 70px);

    &__container {
      position: initial;
      height: 100%;
      max-width: 100%;
      justify-content: center;
      align-items: start;
    }

    .left_block {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px;

      .title {
        text-align: center;
      }
      .desc {
        text-align: center;
      }

      .partners {
        &__cards {
          .card {
            text-align: center;
            h3 {
              text-align: center;
            }
            p {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 655px) {
  .promotion {
    width: 100%;
    padding: 0;

    &__container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0;
      padding: 0;
      margin-top: -18px;
    }

    .left_block {
      justify-content: flex-start;
      align-items: start;
      height: calc(100vh - 60px);
      max-width: 100%;
      gap: 20px;
      padding: 0;

      .title {
        margin-bottom: 0;
        padding: 0 25px;
        text-transform: none;
        color: #272D37;
        text-align: left;

        font-size: 30px;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0em;

        span {
          font-size: 30px;
          font-weight: 700;
          line-height: 39px;
          letter-spacing: 0em;
        }
      }
      .desc {
        text-align: left;
        padding: 0 25px;
        margin-bottom: 0;
        color: #5F6D7E;

        font-size: 18px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
      }

      a {
        width: calc(100% - 50px);
        align-self: center;
        margin-top: auto;
        padding-bottom: 45px;

        button {
          width: 100%;
        }
      }

      .hero_img_mobile {
        //min-width: 100vw;


        width: 100%;
        height: auto;
      }

      .info_block {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0 25px;

        .info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 15px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .promotion {
    &__container {
      .left_block {
        .title {
          p {
            &:first-child {
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}