.button {
  width: 265px;
  height: 46px;
  border-radius: 6px;
  gap: 6px;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  background: none;
  color: var(--white);
  cursor: pointer;
  transition: 0.3s;

  text-align: center;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.05em;

  &.default {
    border: 1px solid #51AF3D;
    background: linear-gradient(356.48deg, #86CD77 -7.61%, #51AF3D 21.65%, #51AF3D 79.4%, #86CD77 104.93%);
    box-shadow: 0 1px 2px 0 #1018280A;
    transition: var(--transition);

    &:hover {
      background: linear-gradient(356.48deg, #86CD77 -7.61%, #29980f 21.65%, #29980f 79.4%, #86CD77 104.93%);
      border-color: #29980f;
      transition: var(--transition);
    }
  }
  &.variant2 {
    background: var(--white);
    border: 1px solid #51AF3D;
    color: #272D37;

    &:hover {
      background: #29980f;
      border-color: #29980f;
      color: var(--white);
    }
  }
}