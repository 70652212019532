.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  justify-content: center;
  align-items: start;
  flex: 1;

  &--fixed {
    margin-top: 66px;
  }
}

.footer {
  flex-shrink: 0;
}